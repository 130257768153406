<template>
  <div>
    
    <modal className="w-full md:w-2/5 xl:w-3/10 pt-10" ref="modal">
      
      <h4 class="text-xl font-bold mb-16">Add Expense Sub Category</h4>

      <form @submit.prevent="submit">

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-16">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <form-group
          type="select"
          :options="categoryOptions"
          left-icon="albums-outline"
          name="category"
          :loading="categories.loading"
          :form="form"
          v-model="form.data.category.value"
        >
          Expense Category
        </form-group>
        <div class="-mt-6 mb-6">
          <a href="#!" class="text-sm text-gray-700" @click.prevent="addCategory">+ Add Category</a>
        </div>

        <form-group
          left-icon="albums-outline"
          name="label"
          :form="form"
          v-model="form.data.label.value"
        >
          Expense Sub Category Label
        </form-group>

        <div class="text-center">
          <button type="submit" class="btn btn-blue btn-md" :disabled="form.loading">
            <span v-if="form.loading">Saving</span>
            <span v-else>Save Sub Category</span>
          </button>
        </div>

      </form>

    </modal>

    <create-expense-category @success="categoryAdded" ref="createExpenseCategory" />

  </div>
</template>

<script>
  export default {
    data() {
      return {
        categories: this.$options.resource([]),
        form: this.$options.basicForm([
          'label', 'category'
        ]),
      }
    },
    computed: {
      categoryOptions() {
        return this.categories.data.map( category => ({ title: category.label, value: category.id }) );
      },
    },
    beforeMount() {
      this.getCategories();
    },
    methods: {
      close() {
        this.$refs.modal.close();
      },
      open() {
        this.$refs.modal.open();
      },
      addCategory() {
        this.$refs.createExpenseCategory.open();
      },
      categoryAdded(category) {
        this.getCategories();
        this.form.data.category.value = category.id;
      },
      async getCategories () {
        this.categories.loading = true;

        await this.sendRequest('corporate.expenseCategories.all', this.user.company_id, {
          success: response => {
            this.categories.data = response.data.data;
          },
          error: error => {
            this.categories.error =  error;
          }
        });

        this.categories.loading = false;
      },
      async submit() {
        this.form.loading = true;

        await this.sendRequest('corporate.expenseSubCategories.new', {
          data: {
            label: this.form.data.label.value,
            slug: this.form.data.label.value.replace(' ', '_').toLowerCase(),
            company_id: this.user.company_id,
            company_expense_category_id: this.form.data.category.value,
          },
          success: response => {
            this.form = this.resetForm(this.form);
            this.$emit('success', response.data.data);
            console.log('happened here');
            this.close();
          },
          error: error => {
            this.form.error =  error;
            this.mapFormError(this.form, error.response?.data?.errors);
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>